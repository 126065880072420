import request from '@/utils/request.js';

const url = "/manage/clue/sale";

/**
 * 分页查询所有咨询师
 */
export function findSalePage(params) {
    return request({
        url: `${url}/find-sale-page`,
        method: 'get',
        params: params
    })
}

/**
 * 保存用户机会池
 */
export function batchPond(params) {
    return request.post(`${url}/batch-pond`, JSON.stringify(params));
}

/**
 * 批量修改关键字段
 * @param params
 */
export function batchCrux(params){
    return request.post(`${url}/batch-crux`, JSON.stringify(params));
}
/**
 * 获取详情
 */
export function infoClueSale(id) {
    return request({
        url: `${url}/info/${id}`,
        method: 'get',
    })
}