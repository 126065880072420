import request from '@/utils/request.js';

const url = "/manage/clue/crux";

/**
 * 全部查询
 */
export function listClueCrux(params) {
  return request({
    url: `${url}/list`,
    method: 'get',
    params: params
  })
}

/**
 * 分页查询
 */
export function pageClueCrux(params) {
  return request({
    url: `${url}/page`,
    method: 'get',
    params: params
  })
}

/**
 * 获取详情
 */
export function infoClueCrux(id) {
  return request({
    url: `${url}/info/${id}`,
    method: 'get',
  })
}

/**
 * 修改
 */
export function updateClueCrux(params) {
  return request.put(`${url}`, JSON.stringify(params));
}

/**
 * 新增
 */
export function addClueCrux(params) {
  return request.post(`${url}`, JSON.stringify(params));
}

/**
 * 删除
 */
export function deleteClueCrux(params) {
  return request.delete(`${url}/info`, JSON.stringify(params));
}
