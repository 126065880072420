<template>
    <div class="app-container">
        <el-row :gutter="10">
            <el-col :span="4">
                <div style="text-align: center;font-size: 13px;color: #ff2b2b;">点击下方部门筛选数据</div>
                <el-tree
                        :current-node-key="queryParams.deptId"
                        :highlight-current="true"
                        :data="deptTree"
                        :expand-on-click-node="false"
                        :filter-node-method="filterNode"
                        :props="{children: 'children', hasChildren: 'hasChildren', label: 'title'}"
                        node-key="id"
                        ref="tree"
                        default-expand-all
                        @node-click="handleNodeClick"
                />
            </el-col>
            <el-col :span="20">
                <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
                    <el-form-item label="名称" prop="name">
                        <el-input
                                v-model="queryParams.nickName"
                                clearable
                                size="mini"
                        />
                    </el-form-item>
                    <el-form-item label="部门" prop="deptId">
                        <el-select v-model="queryParams.deptId" size="mini" clearable>
                            <el-option label="全部" value=""></el-option>
                            <el-option :value="item.id" :label="item.deptName" v-for="item in deptList"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="员工状态" prop="accountStatus">
                        <el-select v-model="queryParams.accountStatus" size="mini" clearable>
                            <el-option label="全部" value=""/>
                            <el-option label="在职" :value="1"/>
                            <el-option label="离职" :value="2"/>
                            <el-option label="停用" :value="0"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索
                        </el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                    </el-form-item>
                </el-form>


                <el-row :gutter="10" class="mb8">
                    <el-col :span="1.5">
                        <el-button
                                type="primary"
                                plain
                                v-permission="'clue:config:pond'"
                                size="mini"
                                @click="handlePond"
                        >配置机会池
                        </el-button>
                    </el-col>
                    <el-col :span="1.5">
                        <el-button
                                type="primary"
                                plain
                                v-permission="'clue:config:update'"
                                size="mini"
                                @click="handleUpdate"
                        >修改配置
                        </el-button>
                    </el-col>
                </el-row>

                <el-table max-height="660" v-loading="loading" :data="saleList"
                          @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55" align="center"/>
                    <el-table-column label="咨询师姓名" align="center" prop="nickName"/>
                    <el-table-column label="部门" align="center" prop="deptId">
                        <template v-slot="scope">
                            <span v-for="item in deptList" :key="item.id"
                                  v-if="scope.row.deptId === item.id">{{ item.deptName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="指定机会池" align="center" prop="pondIds">
                        <template v-slot="scope">
                            <div v-if="scope.row.pondList && scope.row.pondList.indexOf('*') > -1">
                                <el-tag type="success">全部</el-tag>
                            </div>
                            <div v-else>
                                <el-tag v-for="item in pondList" :key="item.id"
                                        v-if="scope.row.pondList && scope.row.pondList.indexOf(item.id + '') > -1">
                                    {{ item.name }}
                                </el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="'权限包：\n'+item.title" align="center" prop="permissionJson"
                                     v-for="item in cruxList">
                        <template v-slot="scope">
                            <div>{{ showPermission(item, scope.row.permissionJson) }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" fixed="right" align="center" class-name="small-padding fixed-width">
                        <template v-slot="scope">
                            <el-button
                                    size="mini"
                                    type="text"
                                    v-permission="'clue:config:pond'"
                                    @click="handlePond(scope.row)"
                            >机会池
                            </el-button>
                            <el-button
                                    size="mini"
                                    type="text"
                                    v-permission="'clue:config:update'"
                                    @click="handleUpdate(scope.row)"
                            >修改
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <pagination
                        v-show="total>0"
                        :total="total"
                        :page.sync="queryParams.current"
                        :limit.sync="queryParams.size"
                        @pagination="getList"
                />
            </el-col>
        </el-row>

        <!--    修改池子    -->
        <el-dialog title="配置机会池" :visible.sync="openPond" width="80%" append-to-body>
            <el-form ref="form" :model="formPond" :rules="rulesPond" label-width="120px" size="mini">
                <el-form-item label="指定线索池" prop="pondIds">
                    <el-checkbox-group v-model="formPond.pondList">
                        <el-checkbox label="*">全部</el-checkbox>
                        <el-checkbox v-for="item in pondList" :label="item.id + ''">{{ item.name }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitPond">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>

        <!-- 配置关键字段 -->
        <el-dialog title="配置关键字段" :visible.sync="open" width="80%" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="120px" size="mini">

                <el-form-item label="项目" prop="subject">
                    <div style="border: 1px groove;min-height: 50px;padding: 10px;">
                        <el-checkbox-group v-model="formMap.subject.value">
                            <el-checkbox label="*">全部</el-checkbox>
                            <el-checkbox :label="null">未指定</el-checkbox>
                            <el-checkbox v-for="item in subjectList" :label="item.id">{{
                                item.subjectName
                                }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-form-item>
                <el-form-item label="所属团队" prop="BHBIGCFCIH">
                    <div style="border: 1px groove;min-height: 50px;padding: 10px;">
                        <el-checkbox-group v-model="formMap.BHBIGCFCIH.value">
                            <el-checkbox label="*">全部</el-checkbox>
                            <el-checkbox :label="0">未指定</el-checkbox>
                            <el-checkbox v-for="item in extraMap['BHBIGCFCIH']" :label="item.id">{{
                                item.dictLabel
                                }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-form-item>
                <el-form-item label="升级机会来源-分类" prop="BHBIIGGHFE">
                    <div style="border: 1px groove;min-height: 50px;padding: 10px;">
                        <el-checkbox-group v-model="formMap.BHBIIGGHFE.value">
                            <el-checkbox label="*">全部</el-checkbox>
                            <el-checkbox :label="0">未指定</el-checkbox>
                            <el-checkbox v-for="item in extraMap['BHBIIGGHFE']" :label="item.id">{{
                                item.dictLabel
                                }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-form-item>
                <el-form-item label="唯一配置" prop="possess">
                    <div style="border: 1px groove;min-height: 50px;padding: 10px;">
                        <el-checkbox-group v-model="formMap.possess.value">
                            <el-checkbox label="*">全部</el-checkbox>
                            <el-checkbox v-for="item in possessList" :label="item.id">{{ item.name }}</el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-form-item>
                <el-form-item label="特殊分类" prop="BHBIGCFBGB">
                    <div style="border: 1px groove;min-height: 50px;padding: 10px;">
                        <el-checkbox-group v-model="formMap.BHBIGCFBGB.value">
                            <el-checkbox label="*">全部</el-checkbox>
                            <el-checkbox :label="0">未指定</el-checkbox>
                            <el-checkbox v-for="item in extraMap['BHBIGCFBGB']" :label="item.id">{{
                                item.dictLabel
                                }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import * as cluePondApi from "@/api/clue/cluePond";
import * as clueSaleApi from "@/api/clue/clueSale";
import * as clueCrueApi from "@/api/clue/clueCrux";
import * as deptApi from "@/api/system/dept";
import * as possApi from "@/api/crm/possess";
import * as possessApi from "@/api/crm/possess";
import * as dectApi from '@/api/system/dictionary'
import * as subjectApi from '@/api/system/subject'
import {batchCrux, batchPond, batchSave} from "../../api/clue/clueSale";

export default {
    name: "MyClueConfig",
    data() {
        return {
            deptId: 1,
            deptTree: [],
            checkedDept: [],
            activeName: "1",
            queryParams: {
                current: 1,
                size: 10,
                deptId: null,
                accountStatus: 1,
                nickName: null,
            },
            form: {},
            formPond: {},
            title: "",
            total: 0,
            open: false,
            openPond: false,
            rules: {},
            rulesPond: {},
            loading: false,
            saleList: [],
            adviserList: [],
            pondList: [],
            expandall: false,
            defaultProps: {
                children: 'children',
                label: 'title'
            },
            deptList: [],
            cruxList: [],
            extraMap: {},
            subjectList: [],
            possessList: [],
            formMap: {
                subject: {
                    value: [],
                    type: "SELF"
                },
                BHBIGCFCIH: {
                    value: [],
                    type: "EXTRA"
                },
                BHBIIGGHFE: {
                    value: [],
                    type: "EXTRA"
                },
                possess: {
                    value: [],
                    type: "SELF"
                },
                BHBIGCFBGB: {
                    value: [],
                    type: "EXTRA"
                }
            },
        };
    },
    created() {
        this.findDeptList();
        this.getDeptTree();
        this.findPondList();
        this.findClueCruxList();
        this.findDictList()
        this.findSubjectList()
        this.findPossessList()
        this.getList();
    },
    methods: {
        /**
         * 查询唯一配置
         */
        findPossessList() {
            possessApi.listAll({}).then(res => {
                this.possessList = res.data
            })
        },
        /**
         * 查询所有专业
         */
        findSubjectList() {
            subjectApi.subjectList({}).then(res => {
                this.subjectList = res.data
            })
        },
        /**
         * 查询字典
         */
        findDictList() {
            dectApi.findExtraDictMap().then(res=>{
                if(res.success){
                    this.extraMap = res.data
                }
            })
            // dectApi.findChildrenByCode("BHBIGCFBGB").then(resp => {
            //     if (resp.success) {
            //         this.extraMap['BHBIGCFBGB'] = resp.data
            //     }
            // })
            // dectApi.findChildrenByCode("BHBIIGGHFE").then(resp => {
            //     if (resp.success) {
            //         this.extraMap['BHBIIGGHFE'] = resp.data
            //     }
            // })
            // dectApi.findChildrenByCode("BHBIGCFCIH").then(resp => {
            //     if (resp.success) {
            //         this.extraMap['BHBIGCFCIH'] = resp.data
            //     }
            // })
        },
        /**
         * 展示权限
         */
        showPermission(crux, row) {
            let jsonArr = row[crux.type]
            if (!jsonArr || jsonArr.length == 0) {
                return "未指定"
            }
            if (jsonArr.indexOf("*") > -1) {
                return "全部"
            }
            if (crux.source === 'EXTRA') {
                let perm = ""
                for (let item of jsonArr) {
                    if (item) {
                      for (let it of this.extraMap[crux.type]) {
                        if ((item * 1) === it.id) {
                          perm += it.dictLabel + ' '
                        }
                      }
                    } else {
                      perm += '未指定 '
                    }
                }
                return perm
            }
            if (crux.type === 'subject') {
                let perm = ""
                for (let item of jsonArr) {
                    if (item == null) {
                        perm += '未指定 '
                    } else {
                        for (let it of this.subjectList) {
                            if ((item * 1) === it.id) {
                                perm += it.subjectName + ' '
                            }
                        }
                    }
                }
                return perm
            }
            if (crux.type === 'possess') {
                let perm = ""
                for (let item of jsonArr) {
                    for (let it of this.possessList) {
                        if ((item * 1) === it.id) {
                            perm += it.name + ' '
                        }
                    }
                }
                return perm
            }
            return crux.type
        },
        /**
         * 查询关键字段
         */
        findClueCruxList() {
            clueCrueApi.listClueCrux({}).then(res => {
                this.cruxList = res.data
            })
        },
        /**
         * 查询数据池
         */
        findPondList() {
            cluePondApi.listCluePond({}).then(res => {
                this.pondList = res.data
            })
        },
        /**
         * 查询部门列表
         */
        findDeptList() {
            deptApi.list({}).then(res => {
                this.deptList = res.data
            })
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        // 节点单击事件
        handleNodeClick(data) {
            this.queryParams.deptId = data.id
            this.getList();
        },
        getDeptTree(id) {
            possApi.findDeptTreeByPoss(id).then(res => {
                this.deptTree = res.data;
            })
        },
        /** 查询参数配置列表 */
        getList() {
            this.loading = true;
            clueSaleApi.findSalePage(this.queryParams).then(response => {
                this.saleList = response.data.data;
                for (let item of this.saleList) {
                    let pondIds = item.pondIds
                    if (pondIds) {
                        let pondList = pondIds.split(",").filter((it) => it)
                        item['pondList'] = pondList
                    }
                    let permissions = item.permissions
                    if (permissions) {
                        item['permissionJson'] = JSON.parse(item.permissions)
                    } else {
                        item['permissionJson'] = {}
                    }
                }
                this.total = response.data.total;
                this.loading = false;
            });
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.openPond = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                pondIds: null,
                pondList: [],
            };
            this.queryParams = {
                current: 1,
                size: 10,
            }
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.current = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.queryParams = {
                current: 1,
                size: 10,
                deptId: null,
                accountStatus: 1,
                nickName: null
            }
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
            this.multiple = !selection.length
        },
        /**
         * 配置机会池
         */
        handlePond(row) {
            this.formPond = {
                pondIds: null,
                pondList: [],
            };
            let ids = (row.id ? [row.id] : null) || this.ids
            if (!ids || ids.length < 1) {
                this.$message.error("请选择数据")
                return
            }
            this.formPond.ids = ids
            this.openPond = true;

            if (row.pondIds) {
                let pondList = row.pondIds.split(",").filter(e => e)
                this.formPond.pondList = pondList
            }
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.formMap = {
                subject: {
                    value: [],
                    type: "SELF"
                },
                BHBIGCFCIH: {
                    value: [],
                    type: "EXTRA"
                },
                BHBIIGGHFE: {
                    value: [],
                    type: "EXTRA"
                },
                possess: {
                    value: [],
                    type: "SELF"
                },
                BHBIGCFBGB: {
                    value: [],
                    type: "EXTRA"
                },
            }
            let ids = (row.id ? [row.id] : null) || this.ids
            console.log("handleUpdate", JSON.stringify(ids))
            if (!ids || ids.length < 1) {
                this.$message.error("请选择数据")
                return
            }
            this.form.ids = ids
            this.open = true;
            if (row.permissionJson) {
                let jsonArr = row.permissionJson
                if (jsonArr) {
                    for (let item in jsonArr) {
                        this.formMap[item] = {"value": jsonArr[item]}
                    }
                }
            }
        },
        submitPond() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    clueSaleApi.batchPond(this.formPond).then(response => {
                        this.$message.success("修改成功");
                        this.openPond = false;
                        this.getList();
                    });
                }
            })
        },
        /** 提交按钮 */
        submitForm() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    let jsStr = {}
                    for (let item in this.formMap) {
                        let va = this.formMap[item]
                        if (va) {
                            if (va['value'].indexOf("*") > -1) {
                                jsStr[item] = ["*"]
                            } else {
                                jsStr[item] = va['value']
                            }
                        }
                    }
                    this.form['permissions'] = JSON.stringify(jsStr)
                    clueSaleApi.batchCrux(this.form).then(response => {
                        this.$message.success("修改成功");
                        this.open = false;
                        this.getList();
                    });
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.ids || this.ids;
            this.$confirm('是否确认删除线索池为"' + ids + '"的数据项?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(function () {
                return cluePondApi.deleteCluePond(ids)
            }).then(() => {
                this.getList();
                this.msgSuccess("删除成功");
            })
        }
    }
}
</script>

<style scoped>
/deep/ .el-tree-node.is-current > .el-tree-node__content {
    background-color: #409eff;
    color: #eeeeee;
}
</style>